'use strict'

###*
 # @ngdoc service
 # @name mundoActions.factory:MundoActions

 # @description

###
angular
  .module 'mundoActions'
  .factory 'MundoActions', [
    () ->
      MundoActionsBase = {}
      MundoActionsBase.actions = {}

      MundoActionsBase.register = (action, callback) ->
        MundoActionsBase.actions[action] = callback

      MundoActionsBase.execute = (action, options) ->
        MundoActionsBase.actions[action](options, action)

      MundoActionsBase
  ]
